<template>
  <div>
    <v-container fluid>
      <v-card class="px-4 py-4">
        <v-row>
          <v-col
            v-for="mix in mixtures"
            :key="mix.publicId"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="3"
          >
            <MixtureCard
              :mixture="mix"
              :mixtures="mixtures"
              :readonly="false"
              :disabled="true"
              :deletable="true"
              @updateMixture="updateMixture"
              @duplicateMixture="duplicateMixture"
              @deleteMixture="deleteMixture"
              :iconColor="iconColor"
              :ref="mix.publicId"
            />
          </v-col>
        </v-row>
        <v-row>
          <MixturePopup
            class="mx-1"
            :inputMixture="emptyMixture()"
            :mixtures="mixtures"
            :show="true"
            iconColor="white"
            buttonClass="success"
            @updateMixture="addMixture"
            ref="addMixturePopup"
          />
          <AddMixturePopup
            class="mx-1"
            :mixtures="databaseMixtures"
            :usedMixtures="[]"
            buttonToolTip="Add Mixture from Database"
            buttonIcon="mdi-database-plus"
            :iconColor="iconColor"
            @addMixture="addMixture"
            ref="addDatabaseMixturePopup"
          />
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import MixtureCard from '@/components/mixtures/MixtureCard';
import MixturePopup from '@/components/mixtures/MixturePopup';
import AddMixturePopup from '@/components/mixtures/AddMixturePopup';

export default {
  name: 'Mixtures',
  components: {
    MixtureCard,
    MixturePopup,
    AddMixturePopup,
  },
  methods: {
    addMixture(mixture) {
      // give mixture(s) from database new public ID
      if (Array.isArray(mixture)) {
        mixture = mixture.map(mix => {
          var mix1 = Object.assign(this.emptyMixture(), mix);
          mix1.publicId = uuidv4();
          return mix1;
        });
      } else {
        mixture = Object.assign(this.emptyMixture(), mixture);
        mixture.publicId = uuidv4();
      }
      this.$store
        .dispatch('mixtures/addMixture', { mixture })
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          if (this.$refs.addDatabaseMixturePopup.dialog) {
            this.$refs.addDatabaseMixturePopup.close();
          }
          if (this.$refs.addMixturePopup.dialog) {
            this.$refs.addMixturePopup.close();
          }
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    updateMixture(mixture) {
      this.$store
        .dispatch('mixtures/updateMixture', mixture)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
          this.$refs[mixture.publicId][0].$refs.mixturePopup.close();
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    duplicateMixture(mixture) {
      this.$store
        .dispatch('mixtures/duplicateMixture', mixture)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    deleteMixture(mixtureToDelete) {
      this.$store.dispatch(
        'mixtures/deleteMixture',
        mixtureToDelete.publicId,
      );
    },
  },
  computed: {
    ...mapGetters('mixtures', [
      'mixtures',
      'emptyMixture',
      'databaseMixtures',
    ]),
    ...mapGetters(['iconColor']),
  },
  mounted() {
    this.$store.dispatch('mixtures/updateCoupledMixtures');
  },
};
</script>

<style></style>
